const Gender = {
    male: {
        name: 'Male',
        value: 'male'
    },
    female: {
        name: 'Female',
        value: 'female'
    }
};

export default Gender;
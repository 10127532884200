import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputBase,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

Input.propTypes = {
    label: PropTypes.string,
    helpertext: PropTypes.string,
    error: PropTypes.bool,
    inputlabelprops: PropTypes.string,
};

export default function Input(props) {
    const { label, helpertext, error } = props;

    return (
        <FormControl fullWidth>
            {label && (
                <Typography variant="caption" sx={{ textAlign: 'left' }}>
                    {label}
                </Typography>
            )}
            <InputBase fullWidth helpertext="" {...props} />
            {helpertext && (
                <FormHelperText error={error}>{helpertext}</FormHelperText>
            )}
        </FormControl>
    );
}
